import React, {useState, useEffect} from 'react';
//import client from '../../../../feathers';
import ImageGallery from 'react-image-gallery';
import Moment from 'react-moment';
import client from '../../demo/client';

function ItemDetails(props) {

  const [itemId, setItemId] = useState(props.match.params.id);
  const [item, setItem] = useState(null);
  const [company, setCompany] = useState({});
  const [images, setImages] = useState([]);
  const [variation, setVariation] = useState(0);
  const [cartButtonStatus, setCartButtonStatus] = useState('Add to Cart');

  const [showPrice, setShowPrice] = useState(true);
  const [pcShow, setPCShow] = useState(false);
  const [pcTerm, setPCTerm] = useState(10);
  const [pcRate, setPCRate] = useState(5.4);
  const [pcPrice, setPCPrice] = useState(0);
  const [pcPayments, setPCPayments] = useState(0);
  const [pcBiWeekly, setPCBiWeekly] = useState(false);
  const [pcToggle, setPCToggle] = useState(false);
  
  useEffect(() => {
    if (typeof props.ReactPixel !== 'undefined') props.ReactPixel.track('track', 'CustomizeProduct');
    if (typeof item !== 'undefined' && item) setPCPrice(getPriceValue(item));
  }, [variation]);

  useEffect(() => {
    
    calculatePayments();
  }, [pcTerm, pcRate, pcPrice, pcBiWeekly])

  useEffect(() => {
    let temp = props.items.find(e => e.id === Number(itemId));
    if (typeof temp !== 'undefined' && temp) {
      
      if (typeof props.track !== 'undefined') props.track(temp.customerId, 'marketItem');
      if (typeof props.trackMarket !== 'undefined')  props.trackMarket(temp.customerId, temp.id, true);

      setItem(temp);
      if (props.includeProfiles) loadCompany(temp.customerId);

      let imageFormat = [];
      temp.images.forEach(image => {
        imageFormat.push({
          original: 'https://api.mcgmedia.net/assets/market/' + temp.customerId + '/' + temp.id + '/' + image.url,
          thumbnail: 'https://api.mcgmedia.net/assets/market/' + temp.customerId + '/' + temp.id + '/' + image.url
        })
      });

      if (imageFormat.length <= 0)
        imageFormat.push({
          original: 'https://api.mcgmedia.net/noimage.jpg',
          thumbnail: 'https://api.mcgmedia.net/noimage.jpg',
        })

      setImages(imageFormat);

      setPCPrice(getPriceValue(temp));

      client.service('account').find({
        query: {
          int_id: temp.customerId
        }
      }).then(res => {
        console.log('ss', res[0]);

        setShowPrice(res[0].bol_ao_show_price_area);
        setPCShow(res[0].bol_ao_show_payment_calc);
        setPCTerm(Number(res[0].int_ao_payment_term));
        setPCRate(Number(res[0].flt_ao_payment_rate));
      })
    }
  }, [,props.items]);

  const loadCompany = (id) => {
    client.service('account').get(id).then(res => {
      
      setCompany(res);
    }).catch(error => {
      console.error(error);
    })
  }
  
  const getValue = (item) => {
    if (typeof item === 'undefined' || !item) return 0;

    if (typeof item.variations[variation] !== 'undefined')
      return item.variations[variation].price;

    return null;
  }

  const isVariationInPrice = (item) => {
    if (typeof item === 'undefined' || !item) return false;

    if (item.variations.length > 1) {
      let lowest = Number.POSITIVE_INFINITY;
      let highest = 0;
      
      for (let i = 0; i < item.variations.length; i++) {
        if (lowest > item.variations[i].price)
          lowest = item.variations[i].price;

        if (highest < item.variations[i].price)
          highest = item.variations[i].price;
      }
      
      if (lowest !== highest)
        return true
    }

    return false;
  }

  const getPrice = (item) => {
    if (typeof item === 'undefined' || !item) return '';

    let value = getValue(item);
    let saleValue = value;

    if (item.onSale && new Date(item.saleEnd) > new Date()) {
      switch (item.discountType) {
        case 'percent':
          saleValue = saleValue * (1 - (item.discountValue / 100));
          break;
        default:
          saleValue -= Number(item.discountValue);
          break;
      }
    }
    
    if (value <= 0) return '';

    return (
      <div>
        {(isVariationInPrice(props.item)) ? <div className="block-item-starting-at">Starting at</div> : ''}
        <span className={(saleValue !== value) ? 'sale-crossout' : ''}>
          {convertToCurrency(value)}
        </span>
        {(saleValue !== value) ? (
          <span className={'block-item-sale-value'}>
            {convertToCurrency(saleValue)}
          </span>
        ) : ''}
      </div>
    );
  }

  const getPriceValue = (item) => {
    let value = getValue(item);
    let saleValue = value;

    if (item.onSale && new Date(item.saleEnd) > new Date()) {
      switch (item.discountType) {
        case 'percent':
          saleValue = saleValue * (1 - (item.discountValue / 100));
          break;
        default:
          saleValue -= Number(item.discountValue);
          break;
      }
    }
    
    if (value <= 0) return 0;

    return (saleValue !== value) ? saleValue : value
  }

  const convertToCurrency = (price) => {
    return '$' + parseFloat(price).toFixed(2);
  }

  const renderVariation = (selected) => {
    if (item.variations.length <= 1) return '';

    let results = [];

    for (let i = 0; i < item.variations.length; i++) {
      const temp = item.variations[i];
      
      results.push(
        <div key={'var' + i} className={'item-variation-option' + ((i === selected) ? ' selected' : '') + ((temp.stock <= 0) ? ' out-of-stock' : '')} onClick={() => { setVariation(i) }}>
          {temp.variation}
        </div>
      )
    }

    return (
      <div className="item-variation-list-wrapper">
        <h3>Variations</h3>
        <div className="item-variation-list">
          {results}
        </div>
      </div>
    );
  }

  const renderCompanyDetails = () => {
    if (Object.keys(company).length === 0) return '';

    return (
      <div className="market-company-profile-container">
        <div className="market-company-profile-logo">
          <a href={company.str_domain} target="_blank">
            <img src={'https://api.mcgmedia.net/assets/' + company.regionId + '/' + company.int_id + '/' + company.str_logo_image} alt={company.str_company_name + ' logo'} />
          </a>
        </div>
        <div className="market-company-profile-contact">
          <div className="mcpro-title">{company.str_company_name}</div>
          {(company.str_phone && company.str_phone.length > 0) ? <div>{company.str_phone}</div> : ''}
          {(company.str_email && company.str_email.length > 0) ? <div>{company.str_email}</div> : ''}
        </div>
        <div className="market-company-profile-address">
          {(company.str_address1 && company.str_address1.length > 0) ? <div>{company.str_address1}</div> : ''}
          {(company.str_city && company.str_city.length > 0) ? <div>{company.str_city} {company.str_province}</div> : ''}
          {(company.str_pcode && company.str_pcode.length > 0) ? <div>{company.str_pcode}</div> : ''}
        </div>
      </div>
    );
  }

  const getStockCount = () => {
    let result = 0;

    item.variations.forEach(variation => {
      result += variation.stock;
    })

    return result;
  }

  const calculatePayments = () => {
    let result = 0;

    //console.log(pcPrice, pcTerm, pcRate, pcBiWeekly)
    if (Number(pcPrice) <= 0) return;
    
    let n = Number(pcTerm) * 12;
    let r = Number(pcRate) / 1200;

    if (pcBiWeekly) {
      n = Number(pcTerm) * 26;
      r = Number(pcRate) / 2600;
    }

    
    //console.log('values', 'price', pcPrice, 'payments', n, 'rate', r)
    result = (Number(pcPrice) * r * (Math.pow(1+r, n)) / (Math.pow(1+r, n) - 1));
    setPCPayments(result);
  }

  if (typeof item === 'undefined' || !item)
  return (
    <div className="position-loader">
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      <div className="loader-text">Loading Item...</div>
    </div>
  );

  console.log('detailsShowBtn:', props.showBuyButton);

  return (
    <div className="item-details-wrapper">
      <div className="item-details-back-link">
          <div className="mcg-div-to-link" onClick={() => props.history.push('/marketplace/')}><i className="fas fa-angle-double-left"></i> Back to marketplace</div>
      </div>
      <div className="item-details-container">
        <div className="item-details-images">
          <ImageGallery items={images}
            showPlayButton={false}
            showFullscreenButton={false} />
        </div>
        <div className="item-details-sidearea">
          {(((typeof item.onSale !== 'undefined' && item.onSale) && item.saleEnd > new Date()) ||  ((new Date() - Date.parse(item.createdAt)) / 1000) <= 86400) ? (
            <div className="item-details-tag">
              {(typeof props.item !== 'undefined' && props.item && props.item.onSale) ? 'Sale' : 'New'}
            </div>
          ) : ''}
          <h2 className="item-details-title">{item.title}</h2>
          <div className="block-item-brand">{((Object.keys(company).length !== 0) ? company.str_company_name : '')}</div>
          <div className="block-item-brand">{(item.subTitle) ? item.subTitle : ''}{(item.subTitle && item.category) ? ' | ' : ''}{(item.category) ? item.category.category : ''}</div>
          <div className={(!item.allowShipping) ? 'item-details-shipable show' : 'item-details-shipable'}>Warning: This item cannot be shipped</div>
          {renderVariation(variation)}
          <div className="item-details-description" dangerouslySetInnerHTML={{__html: item.description}}></div>
          
          {(typeof item.saleEnd !== 'undefined' && item.saleEnd && item.saleEnd > new Date()) ? <div className="item-details-expiry">Sale ends <Moment date={item.saleEnd} fromNow /></div> : ''}
          <div className="item-details-prices">
            {getPrice(item)}
          </div>
          {(getStockCount() < 3 && getStockCount() > 0) ? (
            <div className="item-details-stock-remaining">
              Low Stock: {getStockCount()} Remaining
            </div>
          ) : ''}
          {(props.showBuyButton) ? ((!props.ecommerceEnabled && item.externalUrl && item.externalUrl.toString().trim().length > 0) ? <a className="mcg-button" href={item.externalUrl} target="_blank"><i className="fas fa-external-link-square-alt"></i> Buy Online</a> : (
            (props.ecommerceEnabled) ? (
              (((item.trackStock && getStockCount() > 0 && item.variations[variation].stock > 0)) || !item.trackStock) ? (
                <div>
                  <div className={(cartButtonStatus === 'Added') ? 'add-to-cart added-to-cart' : 'add-to-cart'} onClick={e => {
                    let cartItem = {
                      id: item.variations[variation].id,
                      item: item,
                      name: item.title,
                      image: (typeof images !== 'undefined' && images.length > 0) ? images[0].original : 'https://api.mcgmedia.net/noimage.jpg',
                      description: (typeof item.description !== 'undefined' && item.description && item.description.length > 0) ? item.description.substr(0,30) : '',
                      variation: item.variations[variation],
                      price: getPriceValue(item),
                      quantity: 1
                    }
                    setCartButtonStatus('Added');
                    setTimeout(() => {
                      setCartButtonStatus('Add to Cart');
                    }, 1000);
                    //console.log('cartItem', cartItem);
                    props.addToCart(e, cartItem);
                  }}>{cartButtonStatus}</div>
                </div>
              ) : (
                <div>
                  <div className="add-to-cart out-of-stock">Out of Stock</div>
                </div>
              )
            ) : ''
          )) : ''}
        </div>
      </div>
      {(pcShow) ? (
      <>
      <div className="payment-calculator-toggle" onClick={() => { setPCToggle(!pcToggle); }}><i className="fas fa-calculator"></i> Show Calculator</div>
      <div className={(pcToggle) ? 'payment-calculator show' : 'payment-calculator'}>
        <h3>Payment Calculator</h3>
        <div className="payment-calculator-wrapper">
          <div className="payment-calculator-section">
            <div className="payment-calculator-label">Price</div>
            <input defaultValue={pcPrice} onChange={e => {
              setPCPrice(e.currentTarget.value);
            }} />
          </div>
          <div className="payment-calculator-section">
            <div className="payment-calculator-label">Rate</div>
            <input value={pcRate} onChange={e => {
              setPCRate(e.currentTarget.value);
            }} />
          </div>
          <div className="payment-calculator-section">
            <div className="payment-calculator-label">Term</div>
            <select value={pcTerm} selected={pcTerm} onChange={e => {
                setPCTerm(Number(e.currentTarget.value));
              }}>
              <option selected={(pcTerm === 1) ? 'selected' : ''} value={1}>1 Year</option>
              <option selected={(pcTerm === 2) ? 'selected' : ''} value={2}>2 Year</option>
              <option selected={(pcTerm === 3) ? 'selected' : ''} value={3}>3 Year</option>
              <option selected={(pcTerm === 4) ? 'selected' : ''} value={4}>4 Year</option>
              <option selected={(pcTerm === 5) ? 'selected' : ''} value={5}>5 Year</option>
              <option selected={(pcTerm === 6) ? 'selected' : ''} value={6}>6 Year</option>
              <option selected={(pcTerm === 7) ? 'selected' : ''} value={7}>7 Year</option>
              <option selected={(pcTerm === 8) ? 'selected' : ''} value={8}>8 Year</option>
              <option selected={(pcTerm === 9) ? 'selected' : ''} value={9}>9 Year</option>
              <option selected={(pcTerm === 10) ? 'selected' : ''} value={10}>10 Year</option>
              <option selected={(pcTerm === 11) ? 'selected' : ''} value={11}>11 Year</option>
              <option selected={(pcTerm === 12) ? 'selected' : ''} value={12}>12 Year</option>
              <option selected={(pcTerm === 13) ? 'selected' : ''} value={13}>13 Year</option>
              <option selected={(pcTerm === 14) ? 'selected' : ''} value={14}>14 Year</option>
              <option selected={(pcTerm === 15) ? 'selected' : ''} value={15}>15 Year</option>
              <option selected={(pcTerm === 16) ? 'selected' : ''} value={16}>16 Year</option>
              <option selected={(pcTerm === 17) ? 'selected' : ''} value={17}>17 Year</option>
              <option selected={(pcTerm === 18) ? 'selected' : ''} value={18}>18 Year</option>
              <option selected={(pcTerm === 19) ? 'selected' : ''} value={19}>19 Year</option>
              <option selected={(pcTerm === 20) ? 'selected' : ''} value={20}>20 Year</option>
              <option selected={(pcTerm === 21) ? 'selected' : ''} value={21}>21 Year</option>
              <option selected={(pcTerm === 22) ? 'selected' : ''} value={22}>22 Year</option>
              <option selected={(pcTerm === 23) ? 'selected' : ''} value={23}>23 Year</option>
              <option selected={(pcTerm === 24) ? 'selected' : ''} value={24}>24 Year</option>
              <option selected={(pcTerm === 25) ? 'selected' : ''} value={25}>25 Year</option>
            </select>
          </div>
          <div className="payment-calculator-section">
            <div className={(pcBiWeekly) ? 'payment-calculator-button selected' : 'payment-calculator-button'}
            onClick={() => setPCBiWeekly(true)}>
              Bi-Weekly
            </div>
            <div className={(!pcBiWeekly) ? 'payment-calculator-button selected' : 'payment-calculator-button'}
            onClick={() => setPCBiWeekly(false)}>
              Monthly
            </div>
          </div>
          <div className="payment-calculator-section payment-calculator-payments-section">
            <div className="payment-calculator-label">Payments</div>
            ${pcPayments.toFixed(2)}
          </div>
        </div>
      </div>
      </>
      ) : ''}
      <div>
        {renderCompanyDetails()}
      </div>
    </div>
  );
}

export default ItemDetails;