import React, { Component } from 'react';
import ItemBlock from './item';

const displayItems = 20;
let togglePrice = false;
let toggleAlpha = false;
let toggleDate = true;

class Marketplace extends Component {
	constructor(props) {
		super(props);

		this.state = {
      list: this.props.items,
      show: displayItems,
      categoryFilters: (typeof this.props.match.params.category !== 'undefined') ? [this.props.match.params.category] : [],
      sorting: 'date',
      loading: false,
      onSale: (typeof this.props.showSaleItems !== 'undefined' && this.props.showSaleItems) ? true : false,
      textFilter: ''
    };
    //if (typeof this.props.match.params.category !== 'undefined') console.log('match', this.props.match.params.category, this.state.categoryFilters);
    
    this.itemDisplayArea = React.createRef();
    this.getValue = this.getValue.bind(this);
    
    this.expandView = this.expandView.bind(this);
    this.filterList();
  }

  render() {
    if (typeof this.state.list === 'undefined') return '';

    return (
      <div className={(!this.props.showSideBar) ? 'marketplace-item-area filter-row' : 'marketplace-item-area'}>
        <div className={(!this.props.showSideBar) ? 'alt-filters' : 'alt-filters filter-hide'}>
          <div className="alt-filter-sort-container">
            <div className="alt-filter-sort">
              <div className="alt-filter-header">Sort</div>
              <div onClick={() => { this.setState({ sorting: 'alphabetical' }, this.sortList) }}>Alphabetical</div>
              <div onClick={() => { this.setState({ sorting: 'price' }, this.sortList) }}>Price</div>
              <div onClick={() => { this.setState({ sorting: 'date' }, this.sortList) }}>{(toggleDate) ? 'Newest' : 'Oldest'}</div>
            </div>
          </div>
          <div>
            <div className="filter-search"><input type="text" placeholder="Search by name, brand, or category" onChange={(e) => { this.setState({textFilter: e.currentTarget.value}, this.filterList) }} /></div>
          </div>
        </div>
        <div className={(!this.props.showSideBar) ? 'item-filters filter-hide' : 'item-filters'}>
          <h2>Search</h2>
          <div>
            <div className="filter-search"><input type="text" placeholder="Search by name, brand, or category" onChange={(e) => { this.setState({textFilter: e.currentTarget.value}, this.filterList) }} /></div>
          </div>
          <h2>Sort</h2>
          <div className="item-filter-categories">
            <div onClick={() => { this.setState({ sorting: 'alphabetical' }, this.sortList) }}>Alphabetical</div>
            <div onClick={() => { this.setState({ sorting: 'price' }, this.sortList) }}>Price</div>
            <div onClick={() => { this.setState({ sorting: 'date' }, this.sortList) }}>{(toggleDate) ? 'Newest' : 'Oldest'}</div>
          </div>
          <h2>Filters</h2>
          <div className="item-filter-categories">
            <div className={(this.state.onSale) ? 'selected' : ''} onClick={() => { this.setState({ onSale: !this.state.onSale }, this.filterList); }}>On Sale</div>
          </div>
          <h2>Categories</h2>
          <div className="item-filter-categories">
            { (this.props.showSector) ? this.renderSectors() : this.renderCategories() }
          </div>
        </div>
        <div className="item-list-wrapper">
          <div ref={this.itemDisplayArea} className="item-block-display-container">
            {this.renderItems()}
          </div>
          {(this.state.list.filter(e => e.filtered === false).length > (this.state.show)) ? (
            <div className="mcg-button" onClick={this.expandView}>
              {(this.state.loading) ? <i className="fas fa-spinner rotate more-item-loader"></i> : ''}
              Show More
            </div>
          ) : ''}
        </div>
      </div>
    );
  }

  renderItems() {
    if (typeof this.state.list === 'undefined') return;
    let results = [];

    let filterCounter = 0;
    for (let index = 0; index < this.state.list.length && filterCounter < this.state.show; index++) {
      const item = this.state.list[index];

      if (item.status === 'public') {
        this.props.trackMarket(item.customerId, item.id);

        results.push(<ItemBlock ReactPixel={this.props.ReactPixel} {...this.props} key={'marketitem' + item.id} customerId={this.props.customerId} item={item} addToCart={this.props.addToCart} ecommerceEnabled={this.props.ecommerceEnabled} />);
      }

      if (!item.filtered) filterCounter++;
    }

    return results;
  }

  renderSectors() {
    if (typeof this.props.items === 'undefined') return;
    let results = [];
    let categories = [];

    for (let index = 0; index < this.props.items.length; index++) {
      const item = this.props.items[index];
      if (typeof item.sector !== 'undefined' && item.sector) {
        if (!categories.includes(item.sector) && item.status === 'public') {
          categories.push(item.sector);
          
        }
      }
    }

    let index = 0;
    categories = categories.sort();

    categories.forEach(category => {
      results.push(
        <div key={'category'+index++}
        className={(this.state.categoryFilters.includes(category.toLowerCase().trim().split(' ').join(''))) ? 'selected' : ''}
        onClick={() => {
          console.log('filter sector: ' + category)
          if (this.state.categoryFilters.includes(category.toLowerCase().trim().split(' ').join('')))
            this.setState({ categoryFilters: [] }, this.filterList);
          else
            this.setState({ categoryFilters: [category.toLowerCase().trim().split(' ').join('')] }, this.filterList);
        }}>
          {category}
        </div>
      )
    })

    return results;
  }

  renderCategories() {
    if (typeof this.props.items === 'undefined') return;
    let results = [];
    let categories = [];

    for (let index = 0; index < this.props.items.length; index++) {
      const item = this.props.items[index];
      if (typeof item.category !== 'undefined' && item.category) {
        if (!categories.includes(item.category.category) && item.status === 'public') {
          categories.push(item.category.category);
          
        }
      }
    }

    let index = 0;
    categories = categories.sort();

    categories.forEach(category => {
      results.push(
        <div key={'category'+index++}
        className={(this.state.categoryFilters.includes(category.toLowerCase().trim().split(' ').join(''))) ? 'selected' : ''}
        onClick={() => {
          /*let filters = [...this.state.categoryFilters];
          if (!filters.includes(category.toLowerCase().trim().split(' ').join('')))
            filters.push(category.toLowerCase().trim().split(' ').join(''));
          else
            filters = filters.filter(e => e !== category.toLowerCase().trim().split(' ').join(''));

          this.setState({ categoryFilters: filters }, this.filterList);*/
          console.log('filter category: ' + category)
          if (this.state.categoryFilters.includes(category.toLowerCase().trim().split(' ').join('')))
            this.setState({ categoryFilters: [] }, this.filterList);
          else
            this.setState({ categoryFilters: [category.toLowerCase().trim().split(' ').join('')] }, this.filterList);

        }}>
          {category}
        </div>
      )
    })

    return results;
  }

  expandView() {
    let item = {};
    for (let i = this.state.show; i < this.state.show + displayItems; i++) {
      if (this.state.list.length > i) {
        item = this.state.list[i];
      }
    }

    this.setState({ show: this.state.show + displayItems });
  }

  filterList() {
    if (typeof this.state.list === 'undefined') return;

    let results = this.state.list;

    for (let i = 0; i < results.length; i++) {
      if (this.state.onSale && !(results[i].onSale && new Date(results[i].saleEnd) > new Date()))
        results[i].filtered = true;
      else
        results[i].filtered = false;
    }

    console.log('categoryFilters', this.state.categoryFilters, this.state.list);
    let filter = [];
    if (this.state.categoryFilters.length > 0) {
      filter = this.state.list.filter(item => {
        if (item.category) {
          if (this.props.showSector && item.sector !== null) {
            return this.state.categoryFilters.includes(item.sector.toLowerCase().trim().split(' ').join(''))
          } else {
            return this.state.categoryFilters.includes(item.category.category.toLowerCase().trim().split(' ').join(''))
          }
        } else
          return false
      })

      for (let i = 0; i < results.length; i++)
        if (!filter.includes(results[i]))
          results[i].filtered = true;
    }

    if (this.state.textFilter.length > 0) {
      let compiledText = '';
      let companyRef;

      for (let i = 0; i < results.length; i++) {
        if (typeof this.props.accounts !== 'undefined')
          companyRef = this.props.accounts.find(e => e.int_id === results[i].customerId)
        
        compiledText = (results[i].title + results[i].category + results[i].subTitle + ((typeof companyRef !== 'undefined') ? companyRef.str_company_name : '')).trim().toLowerCase().split(' ').join('');
        

        if (!compiledText.includes(this.state.textFilter.toLowerCase().split(' ').join('').trim()))
          results[i].filtered = true;
      }
    }

    this.setState({
      list: results,
      show: displayItems
    }, window.scrollTo(0, 0))
  }

  sortList() {
    switch(this.state.sorting) {
      case 'alphabetical':
        if (toggleAlpha)
          this.setState({ list: [...this.state.list.sort((a,b) => { return a.title.localeCompare(b.title) })] });
        else
          this.setState({ list: [...this.state.list.sort((a,b) => { return b.title.localeCompare(a.title) })] });
        
        toggleAlpha = !toggleAlpha;
        break;
      case 'price':
        if (togglePrice)
          this.setState({ list: [...this.state.list.sort((a,b) => { return this.getPrice(a) - this.getPrice(b) })] });
        else
          this.setState({ list: [...this.state.list.sort((a,b) => { return this.getPrice(b) - this.getPrice(a) })] });

        togglePrice = !togglePrice;
        break;
      case 'date':
      default:
        if (toggleDate)
          this.setState({ list: [...this.state.list.sort((a,b) => { return new Date(b.createdAt) - new Date(a.createdAt) })] });
        else
          this.setState({ list: [...this.state.list.sort((a,b) => { return new Date(a.createdAt) - new Date(b.createdAt) })] });

        toggleDate = !toggleDate;
        break;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      let list = [];
      for (let index = 0; index < this.props.items.length; index++) {
        var item = this.props.items[index];
        item.filtered = false;
        list.push(item);
      }
      this.setState({list}, this.sortList);
    }
  }

  componentDidMount() {
    this.sortList();
  }



  getValue(item) {
    let value = 0;
    
    if (item.variations.length > 0)
      value = item.variations[0].price;

    if (item.variations.length > 1) {
      let lowest = Number.POSITIVE_INFINITY;
      
      for (let i = 0; i < item.variations.length; i++) {
        if (lowest > item.variations[i].price)
          lowest = item.variations[i].price;
      }
      value = lowest;
    }

    return value;
  }

  getPrice(item) {
    let value = this.getValue(item);
    let saleValue = value;

    if (item.onSale && new Date(item.saleEnd) > new Date()) {
      switch (item.discountType) {
        case 'percent':
          saleValue = saleValue * (1 - (item.discountValue / 100));
          break;
        default:
          saleValue -= Number(item.discountValue);
          break;
      }
    }
    
    if (value <= 0) return '';

    if (saleValue !== value)
      return saleValue;
    

    return value;
  }
}

export default Marketplace;