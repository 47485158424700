import React, { useState } from 'react';

function ItemBlock(props) {
  const [cartButtonStatus, setCartButtonStatus] = useState('Add to Cart');

  const getValue = (item) => {
    let value = 0;
    
    if (item.variations.length > 0)
      value = item.variations[0].price;

    if (item.variations.length > 1) {
      let lowest = Number.POSITIVE_INFINITY;
      
      for (let i = 0; i < item.variations.length; i++) {
        if (lowest > item.variations[i].price)
          lowest = item.variations[i].price;
      }
      value = lowest;
    }

    return value;
  }

  const isVariationInPrice = (item) => {
    if (item.variations.length > 1) {
      let lowest = Number.POSITIVE_INFINITY;
      let highest = 0;
      
      for (let i = 0; i < item.variations.length; i++) {
        if (lowest > item.variations[i].price)
          lowest = item.variations[i].price;

        if (highest < item.variations[i].price)
          highest = item.variations[i].price;
      }
      
      if (lowest !== highest)
        return true
    }

    return false;
  }

  const getPrice = (item) => {
    let value = getValue(item);
    let saleValue = value;

    if (item.onSale && new Date(item.saleEnd) > new Date()) {
      switch (item.discountType) {
        case 'percent':
          saleValue = saleValue * (1 - (item.discountValue / 100));
          break;
        default:
          saleValue -= Number(item.discountValue);
          break;
      }
    }
    
    if (value <= 0) return '';

    return (
      <>
        {(isVariationInPrice(props.item)) ? <div className="block-item-starting-at">Starting at</div> : ''}
        <div className="block-item-price-value-display">
          <div className={(saleValue !== value) ? 'sale-crossout' : ''}>
            {convertToCurrency(value)}
          </div>
          {(saleValue !== value) ? (
            <div className={'block-item-sale-value'}>
              {convertToCurrency(saleValue)}
            </div>
          ) : ''}
        </div>
      </>
    );
  }

  const getPriceValue = (item) => {
    let value = getValue(item);
    let saleValue = value;

    if (item.onSale && new Date(item.saleEnd) > new Date()) {
      switch (item.discountType) {
        case 'percent':
          saleValue = saleValue * (1 - (item.discountValue / 100));
          break;
        default:
          saleValue -= Number(item.discountValue);
          break;
      }
    }
    
    if (value <= 0) return 0;

    return (saleValue !== value) ? saleValue : value
  }

  const convertToCurrency = (price) => {
    return '$' + parseFloat(price).toFixed(2);
  }

  const getStockCount = () => {
    let result = 0;

    props.item.variations.forEach(variation => {
      result += variation.stock;
    })

    return result;
  }

  return (
    <div className={(props.item.filtered) ? 'block-item filter-hide' : 'block-item'} onClick={() => {
      if (typeof props.track !== 'undefined') props.track(props.customerId, 'marketItem');
      if (typeof props.trackMarket !== 'undefined') props.trackMarket(props.item.customerId, props.item.id, true);
      if (typeof props.ReactPixel !== 'undefined') props.ReactPixel.track('track', 'Lead', {content_name: props.item.title});
      props.history.push('/marketplace/' + props.item.id);
    }}>
      <div className="block-item-img">
        {(((typeof props.item.onSale !== 'undefined' && props.item.onSale) && props.item.saleEnd > new Date()) ||  ((new Date() - Date.parse(props.item.createdAt)) / 1000) <= 86400) ? (
          <div className="block-item-tag">
            {(props.item.onSale) ? 'Sale' : 'New'}
          </div>
        ) : ''}
        {(props.item.images.length > 0) ? (
          <img src={'https://api.mcgmedia.net/assets/market/' + props.item.customerId + '/' + props.item.id + '/' + props.item.images.sort((a,b) => a.order - b.order)[0].url} alt={props.item.title} />
        ) : (
          <img src="https://api.mcgmedia.net/noimage.jpg" alt={props.item.title} />
        ) }
      </div>
      <div className="block-item-content">
        <div className="block-item-description">
          <div className="block-item-title">
            {props.item.title}
          </div>
          <div className="block-item-brand">
            {(!props.includeProfiles) ? props.item.subTitle : (typeof props.accounts.find(e => e.int_id === props.item.customerId) !== 'undefined') ? props.accounts.find(e => e.int_id === props.item.customerId).str_company_name : props.item.subTitle}
          </div>
          <div className="block-item-price">
            {getPrice(props.item)}
          </div>
        </div>
      </div>
      {(props.showBuyButton) ? (
        (props.ecommerceEnabled && getValue(props.item) > 0 && props.item.variations.length === 1) ? (
          ((props.item.trackStock && getStockCount() > 0) || !props.item.trackStock) ? (
            <div>
              <div className={(cartButtonStatus === 'Added') ? 'add-to-cart added-to-cart' : 'add-to-cart'} onClick={e => {
                  //console.log('images', props.item, props.images);
                  let cartItem = {
                    id: props.item.variations[0].id,
                    item: props.item,
                    name: props.item.title,
                    image: (typeof props.item.images !== 'undefined' && props.item.images.length > 0) ? 'https://api.mcgmedia.net/assets/market/' + props.item.customerId + '/' + props.item.id + '/' + props.item.images[0].url : 'https://api.mcgmedia.net/noimage.jpg',
                    description: (typeof props.item.description !== 'undefined' && props.item.description && props.item.description.length > 0) ? props.item.description.substr(0,30) : '',
                    variation: props.item.variations[0],
                    price: getPriceValue(props.item),
                    quantity: 1
                  }
                  setCartButtonStatus('Added');
                  setTimeout(() => {
                    setCartButtonStatus('Add to Cart');
                  }, 1000);
                  //console.log('item.js - cartItem', cartItem);
                  props.addToCart(e, cartItem);
                }}>{cartButtonStatus}</div>
            </div>
          ) : (
            <div>
              <div className="add-to-cart out-of-stock">Out of Stock</div>
            </div>
          )
        ) : ''
      ) : ''}
    </div>
  );
}



export default ItemBlock;